import React, { useState, useEffect, useCallback } from "react";
import { auth, firebase } from "../Config/firebase";
import { supabase } from "../Config/supabase";
import {
  Grid,
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import MobileExperienceCard from "../ExperienceCard/MobileExperienceCard";
import AddExperience from "../AddExperience/AddExperience";
import AddIcon from "@mui/icons-material/Add";
import { useAuth } from "../Context/Auth";
import { useCache } from "../Context/CacheContext";
import { useError } from "../Context/ErrorContext";
import "./Home.css";
import foodPhoto from "../Assets/Images/default.png";
import parkPhoto from "../Assets/Images/park.png";

const CACHE_KEY = "experiencesCache";
const CACHE_TTL = 1000 * 60 * 10; // 10 minutes

const Home = () => {
  const { currentUser } = useAuth();
  const { cache, updateCache } = useCache();
  const { error, setQuotaError, clearError } = useError();
  const [experiences, setExperiences] = useState([]);
  const [filteredExperiences, setFilteredExperiences] = useState([]);
  const [activeCategory, setActiveCategory] = useState("restaurant");
  const [signInError, setSignInError] = useState("");
  const [expandedStates, setExpandedStates] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [selectedExperienceId, setSelectedExperienceId] = useState(null);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("restaurant");
  const [preloadedImages, setPreloadedImages] = useState({});

  const preloadImages = (experiences) => {
    const images = {};
    experiences.forEach((experience) => {
      const img = new Image();
      img.src =
        experience.imageUrl ||
        (experience.category === "park/trail" ? parkPhoto : foodPhoto);
      images[experience.id] = img.src;
    });
    setPreloadedImages(images);
  };

  const fetchExperiences = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      const userUid = user.uid;
      try {
        let { data: userExperiences, error } = await supabase
          .from("experiences")
          .select("*")
          .eq("uid", userUid);
        if (error) throw error;

        const sortedExperiences = userExperiences.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setExperiences(sortedExperiences);
        setFilteredExperiences(
          sortedExperiences.filter((exp) => exp.category === activeCategory)
        );
        const updatedCache = { ...cache, experiences: sortedExperiences };
        updateCache("experiences", updatedCache.experiences);
        localStorage.setItem(CACHE_KEY, JSON.stringify(updatedCache));
        localStorage.setItem(`${CACHE_KEY}_time`, Date.now().toString());
        preloadImages(sortedExperiences);
        clearError();
      } catch (error) {
        if (error.message.includes("Quota exceeded")) {
          setQuotaError("Feature is temporarily unavailable.");
        } else {
          console.error("Error fetching experiences:", error);
        }
      }
    }
  }, [cache, updateCache, clearError, setQuotaError, activeCategory]);

  useEffect(() => {
    if (currentUser) {
      const cachedData = localStorage.getItem(CACHE_KEY);
      const cachedTime = localStorage.getItem(`${CACHE_KEY}_time`);

      if (cachedData && cachedTime && Date.now() - cachedTime < CACHE_TTL) {
        const cachedExperiences = JSON.parse(cachedData).experiences;
        const experiencesWithTimestamps = cachedExperiences.map((exp) => ({
          ...exp,
          createdAt: new Date(exp.createdAt),
        }));
        const sortedExperiences = experiencesWithTimestamps.sort(
          (a, b) => b.createdAt - a.createdAt
        );
        setExperiences(sortedExperiences);
        setFilteredExperiences(
          sortedExperiences.filter((exp) => exp.category === activeCategory)
        );
        preloadImages(sortedExperiences);
      } else {
        fetchExperiences();
      }
    }
  }, [currentUser, fetchExperiences, activeCategory]);

  useEffect(() => {
    if (experiences.length) {
      const filtered = experiences.filter(
        (exp) => exp.category === activeCategory
      );
      setFilteredExperiences(filtered);
    }
  }, [activeCategory, experiences]);

  const handleDeleteIconClick = (experienceId) => {
    setSelectedExperienceId(experienceId);
    setOpenForm(true);
  };

  const handleConfirmDelete = async () => {
    const user = auth.currentUser;
    if (user) {
      const userUid = user.uid;
      try {
        const { error } = await supabase
          .from("experiences")
          .delete()
          .eq("id", selectedExperienceId)
          .eq("uid", userUid);
        if (error) throw error;
        handleDeleteExperience(selectedExperienceId);
        setOpenForm(false);
      } catch (error) {
        console.error("Error deleting experience:", error);
      }
    }
  };

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setSelectedCategory(category);
    if (openAddForm) {
      setOpenAddForm(false);
      setTimeout(() => {
        setOpenAddForm(true);
      }, 0);
    }
  };

  const handleCardClick = (id) => {
    setExpandedStates((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await auth.signInWithPopup(provider);
    } catch (error) {
      if (
        error.code === "auth/popup-blocked" ||
        error.code === "auth/popup-closed-by-user"
      ) {
        try {
          await auth.signInWithRedirect(provider);
        } catch (redirectError) {
          console.error(
            "Error signing in with Google using redirect:",
            redirectError
          );
          setSignInError("Failed to sign in. Please try again.");
        }
      } else {
        console.error("Error signing in with Google:", error);
        setSignInError("Failed to sign in. Please try again.");
      }
    }
  };

  const handleSaveNote = (updatedExperience) => {
    const updatedExperiences = experiences.map((exp) =>
      exp.id === updatedExperience.id ? updatedExperience : exp
    );
    const sortedExperiences = updatedExperiences.sort(
      (a, b) => b.createdAt - a.createdAt
    );
    setExperiences(sortedExperiences);
    setFilteredExperiences(
      sortedExperiences.filter((exp) => exp.category === activeCategory)
    );
    const updatedCache = { ...cache, experiences: sortedExperiences };
    updateCache("experiences", updatedCache.experiences);
    localStorage.setItem(CACHE_KEY, JSON.stringify(updatedCache));
  };

  const handleDeleteExperience = (id) => {
    const updatedExperiences = experiences.filter((exp) => exp.id !== id);
    const sortedExperiences = updatedExperiences.sort(
      (a, b) => b.createdAt - a.createdAt
    );
    setExperiences(sortedExperiences);
    setFilteredExperiences(
      sortedExperiences.filter((exp) => exp.category === activeCategory)
    );
    const updatedCache = { ...cache, experiences: sortedExperiences };
    updateCache("experiences", updatedCache.experiences);
    localStorage.setItem(CACHE_KEY, JSON.stringify(updatedCache));
  };

  const handleAddSnapClick = () => {
    setSelectedCategory(activeCategory);
    setOpenAddForm(true);
  };

  const handleFormClose = () => {
    setOpenAddForm(false);
    setSelectedCategory("");
  };

  return (
    <>
      {!currentUser ? (
        <div className="unauthenticated-container">
          <div className="about-us">
            <Typography variant="h4" className="about-us-title">
              Travel Diary
            </Typography>
            <Typography variant="body1" className="about-us-content">
              Discover and save places you want to visit, remember where you've
              been, and explore new destinations in our Feed. Join us and start
              your journey today!
            </Typography>
          </div>
          <div className="google-signin-button-container">
            <Button
              onClick={signInWithGoogle}
              variant="contained"
              sx={{ backgroundColor: "#4285F4", color: "white" }}
              startIcon={<GoogleIcon />}>
              Continue with Google
            </Button>
            {signInError && <p style={{ color: "red" }}>{signInError}</p>}
          </div>
        </div>
      ) : (
        <div className="authenticated-container">
          <Typography
            variant="h4"
            className="placesText"
            sx={{
              mt: 4,
              mb: 2,
            }}>
            Travel Diary
          </Typography>
          <div className="home-filter-buttons-container">
            <Button
              variant={
                activeCategory === "restaurant" ? "contained" : "outlined"
              }
              onClick={() => handleCategoryChange("restaurant")}
              className="home-filter-button">
              Restaurant
            </Button>
            <Button
              variant={
                activeCategory === "park/trail" ? "contained" : "outlined"
              }
              onClick={() => handleCategoryChange("park/trail")}
              className="home-filter-button">
              Park/Trail
            </Button>
          </div>
          {error && (
            <div className="error-container">
              <Typography
                variant="h6"
                sx={{ mt: 4, mb: 2 }}
                style={{ marginLeft: "10px", marginRight: "10px" }}
                className="empty-list-message">
                {error}
              </Typography>
            </div>
          )}

          {!error && !openAddForm && (
            <Button
              variant="outlined"
              onClick={handleAddSnapClick}
              className="add-card-button"
              startIcon={<AddIcon />}
              sx={{
                color: "#e6c629",
                borderColor: "#e6c629",
                backgroundImage: `url(${
                  activeCategory === "restaurant" ? foodPhoto : parkPhoto
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                "&:hover": {
                  borderColor: "#e6c629",
                },
              }}>
              {activeCategory === "restaurant"
                ? "Add Restaurant"
                : "Add Park/Trail"}
            </Button>
          )}
          {openAddForm && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 4,
                mb: 2,
              }}>
              <AddExperience
                key={selectedCategory}
                category={selectedCategory}
                fetchExperiences={fetchExperiences}
                closeDialog={handleFormClose}
              />
            </Box>
          )}

          {filteredExperiences.length === 0 && !error && (
            <div className="home-empty-list-message">
              <Typography variant="h6">
                Your {activeCategory} diary is empty. Click the button above to
                add your first experience!
              </Typography>
            </div>
          )}

          <Box sx={{ flexGrow: 1, mt: 4, px: 2, pb: 10 }}>
            <Grid
              container
              spacing={2}
              style={{
                display: filteredExperiences.length === 1 ? "flex" : "",
                justifyContent: "center",
              }}>
              {filteredExperiences.map((experience) => (
                <Grid item xs={12} key={experience.id}>
                  <MobileExperienceCard
                    experience={experience}
                    preloadedImage={preloadedImages[experience.id]}
                    isExpanded={expandedStates[experience.id]}
                    onClick={() => handleCardClick(experience.id)}
                    onSaveNote={handleSaveNote}
                    onDeleteIconClick={() =>
                      handleDeleteIconClick(experience.id)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Dialog
            open={openForm}
            onClose={() => setOpenForm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Delete Snap"}</DialogTitle>
            <DialogContent>
              Are you sure you want to delete this card?
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenForm(false)}>Cancel</Button>
              <Button onClick={handleConfirmDelete} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default Home;
