import React from "react";
import "./TermsConditions.css";

const TermsConditions = ({ isOpen, onRequestClose }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="cart-nav">
          <button className="close-cart" onClick={onRequestClose}>
            {" "}
            Close
          </button>
        </div>
        <h2>Terms & Conditions</h2>

        <p>Last updated: 06/16/2024</p>
        <br />
        <p>
          By accessing and using the Travel Diary platform (the "Service")
          provided by Travel Diary ("us", "we", or "our"), you agree to be bound
          by these Terms and Conditions.
        </p>
        <br />
        <p>
          If you do not agree with any part of these Terms and Conditions, you
          must not access or use the Service.
        </p>

        <h2>Accounts</h2>
        <br />
        <p>
          When you create an account with us, you must provide accurate,
          complete, and up-to-date information. Failure to do so constitutes a
          breach of the Terms, which may result in immediate termination of your
          account on our Service.
        </p>
        <br />
        <p>
          You are responsible for safeguarding the password that you use to
          access the Service and for any activities or actions under your
          password, whether your password is with our Service or a third-party
          service.
        </p>
        <br />
        <p>
          You agree not to disclose your password to any third party. You must
          notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account.
        </p>

        <h2>Content</h2>
        <br />
        <p>
          Our Service allows you to post, link, store, share and otherwise make
          available certain information, text, graphics, videos, or other
          material ("Content"). You are responsible for the Content that you
          post on or through the Service, including its legality, reliability,
          and appropriateness.
        </p>
        <br />
        <p>
          By posting Content on or through the Service, You represent and
          warrant that: (i) the Content is yours (you own it) and/or you have
          the right to use it and the right to grant us the rights and license
          as provided in these Terms, and (ii) that the posting of your Content
          on or through the Service does not violate the privacy rights,
          publicity rights, copyrights, contract rights or any other rights of
          any person or entity.
        </p>

        <h2>Purchases</h2>
        <br />
        <p>
          If you wish to purchase any product or service made available through
          the Service ("Purchase"), you may be asked to supply certain
          information relevant to your Purchase, including but not limited to
          your name, email address, postal address, and payment information.
        </p>
        <br />
        <p>
          You represent and warrant that you have the legal right to use any
          credit card(s) or other payment method(s) used in connection with any
          Purchase and that the information you supply to us is true, correct,
          and complete.
        </p>

        <h2>Intellectual Property</h2>
        <br />
        <p>
          The Service and its original content (excluding Content provided by
          users), features, and functionality are and will remain the exclusive
          property of Travel Diary and its licensors. The Service is protected
          by copyright, trademark, and other laws of both the United States and
          foreign countries. Our trademarks and trade dress may not be used in
          connection with any product or service without the prior written
          consent of Travel Diary.
        </p>

        <h2>Termination</h2>
        <br />
        <p>
          We may terminate or suspend your account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if you breach the Terms.
        </p>
        <br />
        <p>
          Upon termination, your right to use the Service will immediately
          cease. If you wish to terminate your account, you may simply
          discontinue using the Service.
        </p>

        <h2>Changes</h2>
        <br />
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will make
          reasonable efforts to provide at least 30 days' notice prior to any
          new terms taking effect. What constitutes a material change will be
          determined at our sole discretion.
        </p>
        <br />
        <p>
          By continuing to access or use our Service after any revisions become
          effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, you must stop using the Service.
        </p>
        <h2>Disclaimer</h2>
        <br />
        <p>
          Your use of the Service is at your sole risk. The Service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement, or course of performance.
        </p>
        <br />
        <p>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service and
          supersede and replace any prior agreements we might have had between
          us regarding the Service.
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
