import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Slider,
  Typography,
  Rating,
} from "@mui/material";
import { supabase } from "../Config/supabase"; // Import Supabase client
import { storage, auth } from "../Config/firebase"; // Import Firebase client
import CameraIcon from "@mui/icons-material/CameraAlt";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/CloudUpload";
import "./AddExperience.css";
import foodPhoto from "../Assets/Images/default.png";
import parkPhoto from "../Assets/Images/park.png";

function AddExperience({ fetchExperiences, closeDialog, category }) {
  const [experience, setExperience] = useState({
    category: category || "",
    name: "",
    location: "",
    rating: 1,
    foodOrdered: "",
    spend: "",
    notes: "",
    image: null,
    crowdLevel: 1,
    walkingTrails: "",
  });
  const [filename, setFilename] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(0);
  const [cardBackground, setCardBackground] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setExperience({
      category: category || "",
      name: "",
      location: "",
      rating: 1,
      foodOrdered: "",
      spend: "",
      notes: "",
      image: null,
      crowdLevel: 1,
      walkingTrails: "",
    });
    setStep(0);
    setFilename("");
    setCardBackground(null);
    setErrors({});
  }, [category]);

  const handleChange = (event) => {
    setExperience({ ...experience, [event.target.name]: event.target.value });
  };

  const handleSliderChange = (event, newValue) => {
    setExperience({ ...experience, crowdLevel: newValue });
  };

  const handleRatingChange = (event, newValue) => {
    setExperience({ ...experience, rating: newValue });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 4 * 1024 * 1024) {
        alert("File size exceeds 4 MB. Please select a smaller file.");
        return;
      }
      setExperience({ ...experience, image: file });
      setFilename(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setCardBackground(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const user = auth.currentUser;
    if (!user) {
      console.error("User not authenticated");
      setIsSubmitting(false);
      return;
    }
    const userUid = user.uid;
    const { image, ...experienceData } = experience;

    // Round the crowdLevel value
    experienceData.crowdLevel = Math.round(experienceData.crowdLevel);

    try {
      if (image) {
        const uploadTask = storage.ref(`images/${filename}`).put(image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            console.error("Error uploading image:", error);
            throw error;
          },
          async () => {
            const imageUrl = await storage
              .ref("images")
              .child(filename)
              .getDownloadURL();
            experienceData.imageUrl = imageUrl;

            const { error } = await supabase
              .from("experiences")
              .insert([{ ...experienceData, uid: userUid }]);

            if (error) {
              throw error;
            }

            setExperience({
              category: category,
              name: "",
              location: "",
              rating: 1,
              foodOrdered: "",
              spend: "",
              notes: "",
              image: null,
              crowdLevel: 1,
              walkingTrails: "",
            });

            await fetchExperiences();
            setFilename("");
            setIsSubmitting(false);
            closeDialog();
          }
        );
      } else {
        const { error } = await supabase
          .from("experiences")
          .insert([{ ...experienceData, uid: userUid }]);

        if (error) {
          throw error;
        }

        setExperience({
          category: category,
          name: "",
          location: "",
          rating: 1,
          foodOrdered: "",
          spend: "",
          notes: "",
          image: null,
          crowdLevel: 1,
          walkingTrails: "",
        });

        await fetchExperiences();
        setFilename("");
        setIsSubmitting(false);
        closeDialog();
      }
    } catch (error) {
      console.error("Error adding experience:", error);
      setIsSubmitting(false);
    }
  };

  const fallbackImage =
    experience.category === "park/trail" ? parkPhoto : foodPhoto;

  const isStepComplete = (step) => {
    switch (step) {
      case 0:
        return experience.name.trim() !== "";
      case 1:
        if (experience.category === "restaurant") {
          return (
            experience.rating !== "" &&
            experience.foodOrdered.trim() !== "" &&
            experience.spend.trim() !== ""
          );
        } else if (experience.category === "park/trail") {
          return (
            experience.rating !== "" &&
            experience.crowdLevel !== "" &&
            experience.walkingTrails.trim() !== ""
          );
        } else {
          return experience.rating !== "";
        }
      case 2:
        return (
          experience.location.trim() !== "" && experience.notes.trim() !== ""
        );
      default:
        return false;
    }
  };

  const handleNextStep = () => {
    const newErrors = {};
    if (!isStepComplete(step)) {
      if (step === 0 && experience.name.trim() === "") {
        newErrors.name = true;
      }
      if (step === 1) {
        if (experience.category === "restaurant") {
          if (experience.rating === "") newErrors.rating = true;
          if (experience.foodOrdered.trim() === "")
            newErrors.foodOrdered = true;
          if (experience.spend.trim() === "") newErrors.spend = true;
        } else if (experience.category === "park/trail") {
          if (experience.rating === "") newErrors.rating = true;
          if (experience.crowdLevel === "") newErrors.crowdLevel = true;
          if (experience.walkingTrails.trim() === "")
            newErrors.walkingTrails = true;
        } else {
          if (experience.rating === "") newErrors.rating = true;
        }
      }
      if (step === 2) {
        if (experience.location.trim() === "") newErrors.location = true;
        if (experience.notes.trim() === "") newErrors.notes = true;
      }
      setErrors(newErrors);
      console.log(newErrors);
    } else {
      setErrors({});
      setStep(step + 1);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <div className="camera-section">
              <input
                accept="image/*"
                id="file-input"
                type="file"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <label htmlFor="file-input">
                <Box
                  component="span"
                  sx={{
                    width: 350,
                    height: 150,
                    border: "2px dashed #e6c629",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    zIndex: 1,
                  }}>
                  <IconButton component="span" sx={{ color: "#e6c629" }}>
                    <CameraIcon sx={{ fontSize: 50 }} />
                  </IconButton>
                </Box>
              </label>
              <TextField
                label="Name"
                name="name"
                value={experience.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                error={!!errors.name}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: errors.name ? "red" : "#e6c629",
                    },
                    "& fieldset": {
                      borderColor: errors.name ? "red" : "#e6c629",
                    },
                    "&:hover fieldset": {
                      borderColor: errors.name ? "red" : "#e6c629",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: errors.name ? "red" : "#e6c629",
                  },
                }}
              />
            </div>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <IconButton onClick={handleNextStep} sx={{ color: "#e6c629" }}>
                <ArrowForwardIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <FormControl
              fullWidth
              margin="normal"
              required
              sx={{ paddingTop: "10px" }}
              error={!!errors.rating}>
              <Typography
                component="legend"
                style={{
                  color: errors.rating ? "red" : "#e6c629",
                  marginTop: "10px",
                }}>
                Rating
              </Typography>
              <Rating
                name="rating"
                value={experience.rating}
                onChange={handleRatingChange}
                max={5}
                precision={1}
                sx={{
                  marginBottom: "-18px",
                  "& .MuiRating-iconFilled": {
                    color: "#e6c629",
                    fontSize: "2.5rem", // Increased font size for larger stars
                  },
                  "& .MuiRating-iconEmpty": {
                    color: "white",
                    fontSize: "2.5rem", // Increased font size for larger stars
                  },
                }}
              />
            </FormControl>

            {experience.category === "restaurant" && (
              <>
                <TextField
                  label="Food Ordered"
                  name="foodOrdered"
                  value={experience.foodOrdered}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  error={!!errors.foodOrdered}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: errors.foodOrdered ? "red" : "#e6c629",
                      },
                      "& fieldset": {
                        borderColor: errors.foodOrdered ? "red" : "#e6c629",
                      },
                      "&:hover fieldset": {
                        borderColor: errors.foodOrdered ? "red" : "#e6c629",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "& .MuiInputLabel-root": {
                      color: errors.foodOrdered ? "red" : "#e6c629",
                    },
                  }}
                />
                <TextField
                  label="Amount Spent"
                  name="spend"
                  value={experience.spend}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  type="number"
                  required
                  error={!!errors.spend}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: errors.spend ? "red" : "#e6c629",
                      },
                      "& fieldset": {
                        borderColor: errors.spend ? "red" : "#e6c629",
                      },
                      "&:hover fieldset": {
                        borderColor: errors.spend ? "red" : "#e6c629",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "& .MuiInputLabel-root": {
                      color: errors.spend ? "red" : "#e6c629",
                    },
                  }}
                />
              </>
            )}
            {experience.category === "park/trail" && (
              <>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    id="crowd-level-slider"
                    gutterBottom
                    style={{
                      color: errors.crowdLevel ? "red" : "#e6c629",
                    }}>
                    Crowd Level
                  </Typography>
                  <Slider
                    value={experience.crowdLevel}
                    onChange={handleSliderChange}
                    aria-labelledby="crowd-level-slider"
                    step={0.1}
                    marks
                    min={1}
                    max={5}
                    valueLabelDisplay="auto"
                    sx={{
                      color: errors.crowdLevel ? "red" : "#e6c629",
                    }}
                  />
                </Box>
                <FormControl
                  fullWidth
                  margin="normal"
                  required
                  error={!!errors.walkingTrails}>
                  <InputLabel
                    style={{
                      color: errors.walkingTrails ? "red" : "#e6c629",
                    }}>
                    Walking Trails
                  </InputLabel>
                  <Select
                    name="walkingTrails"
                    value={experience.walkingTrails}
                    onChange={handleChange}
                    label="WalkingTrails"
                    sx={{
                      color: "white",
                      borderColor: errors.walkingTrails ? "red" : "#e6c629",
                      borderWidth: errors.walkingTrails ? 2 : 1,
                      borderStyle: errors.walkingTrails ? "solid" : "none",
                      "& .MuiSelect-select": {
                        color: "white",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: "black",
                          "& .MuiMenuItem-root": {
                            color: "#e6c629",
                          },
                        },
                      },
                    }}>
                    <MenuItem value="Available">Available</MenuItem>
                    <MenuItem value="Not Available">Not Available</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <IconButton
                onClick={() => setStep(step - 1)}
                sx={{ color: "#e6c629" }}>
                <ArrowBackIcon sx={{ fontSize: 40 }} />
              </IconButton>
              <IconButton onClick={handleNextStep} sx={{ color: "#e6c629" }}>
                <ArrowForwardIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <TextField
              label="Location"
              name="location"
              value={experience.location}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              error={!!errors.location}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: errors.location ? "red" : "#e6c629",
                  },
                  "& fieldset": {
                    borderColor: errors.location ? "red" : "#e6c629",
                  },
                  "&:hover fieldset": {
                    borderColor: errors.location ? "red" : "#e6c629",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "white",
                },
                "& .MuiInputLabel-root": {
                  color: errors.location ? "red" : "#e6c629",
                },
              }}
            />
            <TextField
              label="Impressions"
              name="notes"
              value={experience.notes}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              error={!!errors.notes}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: errors.notes ? "red" : "#e6c629",
                  },
                  "& fieldset": {
                    borderColor: errors.notes ? "red" : "#e6c629",
                  },
                  "&:hover fieldset": {
                    borderColor: errors.notes ? "red" : "#e6c629",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "white",
                },
                "& .MuiInputLabel-root": {
                  color: errors.notes ? "red" : "#e6c629",
                },
              }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <IconButton
                onClick={() => setStep(step - 1)}
                sx={{ color: "#e6c629" }}>
                <ArrowBackIcon sx={{ fontSize: 40 }} />
              </IconButton>
              <IconButton
                type="submit"
                sx={{ color: "#e6c629" }}
                disabled={isSubmitting}>
                {isSubmitting ? (
                  <CircularProgress size={40} sx={{ color: "#e6c629" }} />
                ) : (
                  <UploadIcon sx={{ fontSize: 40 }} />
                )}
              </IconButton>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="add-experience-container"
      style={{ paddingTop: "20px", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 20,
          right: 0,
          transform: "translate(50%, -50%)",
          backgroundColor: "rgba(230, 198, 41, 0.5)",
          borderRadius: "50%",
          zIndex: 3, // Ensure the close icon is on top of other elements
        }}>
        <IconButton size="small" sx={{ color: "black" }} onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </Box>
      {experience.category && (
        <Card raised className="expanded-card" sx={{ position: "relative" }}>
          <div
            className="card-background"
            style={{
              backgroundImage: `url(${cardBackground || fallbackImage})`,
            }}></div>
          <CardContent className="card-content" sx={{ color: "white" }}>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              {renderStepContent(step)}
            </form>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

export default AddExperience;
