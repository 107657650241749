import React from "react";
import "./DesktopLandingPage.css";

const DesktopLandingPage = () => {
  return (
    <div className="desktop-landing-page">
      This site is for mobile devices only.
    </div>
  );
};

export default DesktopLandingPage;
