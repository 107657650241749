import React, { useMemo } from "react";
import { Card, Typography, Rating, Box, Link, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import DirectionsIcon from "@mui/icons-material/Directions";
import GoogleIcon from "@mui/icons-material/Google";
import PhoneIcon from "@mui/icons-material/Phone";
import "./RestaurantCard.css";

const RestaurantCard = ({
  restaurant,
  showAddButton = true,
  onAddToWantToGo,
  onDeleteFromWantToGo,
  preloadedImage,
  userLocation,
}) => {
  const displayPrice = (price) => {
    return price ? ` · ${price}` : "";
  };

  const createMapLink = (location) => {
    const fullAddress = `${location.address1}, ${location.city}, ${location.state} ${location.zip_code}`;
    return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      fullAddress
    )}`;
  };

  const createGoogleSearchLink = (restaurant) => {
    const query = `${restaurant.name} ${restaurant.location.address1} ${restaurant.location.city} ${restaurant.location.state}`;
    return `https://www.google.com/search?q=${encodeURIComponent(query)}`;
  };

  const calculateDistance = (userLocation, restaurantLocation) => {
    if (
      !userLocation ||
      !restaurantLocation.latitude ||
      !restaurantLocation.longitude
    )
      return "";

    const { latitude, longitude } = restaurantLocation;

    const toRad = (value) => (value * Math.PI) / 180;

    const earthRadiusMiles = 3958.8; // Radius of the Earth in miles

    const dLat = toRad(latitude - userLocation.latitude);
    const dLng = toRad(longitude - userLocation.longitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(userLocation.latitude)) *
        Math.cos(toRad(latitude)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadiusMiles * c;
    return `${distance.toFixed(2)} mi`;
  };

  const memoizedDistance = useMemo(() => {
    const restaurantLocation = {
      latitude: restaurant.coordinates?.latitude,
      longitude: restaurant.coordinates?.longitude,
    };
    return calculateDistance(userLocation, restaurantLocation);
  }, [userLocation, restaurant.coordinates]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: restaurant.name,
          text: `Check out this place: ${restaurant.name}`,
          url: restaurant.website || restaurant.url,
        });
        console.log("Successfully shared!");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.error("Web Share API is not supported in this browser.");
    }
  };

  return (
    <Card className="restaurant-card">
      <a
        href={restaurant.website || restaurant.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}>
        <div
          className="card-background"
          style={{
            backgroundImage: `url(${
              preloadedImage || restaurant.image_url || "default-image-url.jpg"
            })`,
          }}></div>
      </a>
      <Box className="card-content">
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          className="card-title">
          {restaurant.name}
        </Typography>
        <Typography variant="body2" className="card-text">
          {restaurant.location.display_address
            ? restaurant.location.display_address[0]
            : ""}
          {" · "}
          {memoizedDistance}
          {displayPrice(restaurant.price)}
        </Typography>
        {restaurant.website && (
          <Typography variant="body2" className="card-text">
            <Link
              href={restaurant.website}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#2a5db0" }}
              onClick={(e) => e.stopPropagation()}>
              {restaurant.website}
            </Link>
          </Typography>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" sx={{ marginLeft: -0.5 }}>
            <Rating name="read-only" value={restaurant.rating} readOnly />
            <Typography variant="body2" className="card-text" component="span">
              {`(${restaurant.review_count} reviews)`}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          className="card-buttons"
          sx={{ marginTop: 1 }}>
          <IconButton
            component={Link}
            href={createMapLink(restaurant.location)}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="directions"
            sx={{ color: "#f0e68c" }}
            onClick={(e) => e.stopPropagation()}>
            <DirectionsIcon />
          </IconButton>
          <IconButton
            component={Link}
            href={createGoogleSearchLink(restaurant)}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="search on google"
            sx={{ color: "#f0e68c" }}
            onClick={(e) => e.stopPropagation()}>
            <GoogleIcon />
          </IconButton>
          {restaurant.phone && (
            <IconButton
              component={Link}
              href={`tel:${restaurant.phone}`}
              aria-label="call"
              sx={{ color: "#f0e68c" }}
              onClick={(e) => e.stopPropagation()}>
              <PhoneIcon />
            </IconButton>
          )}
          {showAddButton && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onAddToWantToGo(restaurant);
              }}
              aria-label="add to want to go"
              sx={{ color: "#f0e68c" }}>
              <AddCircleIcon />
            </IconButton>
          )}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleShare();
            }}
            aria-label="share"
            sx={{ color: "#f0e68c" }}>
            <ShareIcon />
          </IconButton>
          {onDeleteFromWantToGo && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onDeleteFromWantToGo(restaurant.id); // Use the correct property for Supabase or Firestore document ID
              }}
              aria-label="delete"
              sx={{ color: "#f0e68c" }}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default RestaurantCard;
