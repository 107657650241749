import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import axios from "axios";
import RestaurantCard from "./RestaurantCard";
import "./FeedPage.css";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import veganImage from "../Assets/Images/vegan.png";
import mexicanImage from "../Assets/Images/mexican.png";
import coffeeImage from "../Assets/Images/coffee.png";
import japanese from "../Assets/Images/japanese.png";
import iceCream from "../Assets/Images/ice-cream.jpg";
import caribbean from "../Assets/Images/caribbean.jpg";
import chinese from "../Assets/Images/chinese.png";
import brunch from "../Assets/Images/brunch.png";
import african from "../Assets/Images/african.png";
import korean from "../Assets/Images/korean.png";
import italian from "../Assets/Images/italian.jpg";
import thai from "../Assets/Images/thai.jpg";
import indian from "../Assets/Images/indian.jpg";
import seafood from "../Assets/Images/seafood.webp";
import mideast from "../Assets/Images/mideast.webp";
import burger from "../Assets/Images/burger.png";
import pizza from "../Assets/Images/pizza.png";
import bakeries from "../Assets/Images/bakeries.jpg";
import fast from "../Assets/Images/fast.jpg";
import chicken from "../Assets/Images/chicken.jpg";
import previewImage from "../Assets/Images/foods.png";
import parkTrails from "../Assets/Images/parktrail.jpg";
import park from "../Assets/Images/park.jpg";
import hike from "../Assets/Images/hike.webp";
import _ from "lodash";
import { auth } from "../Config/firebase";
import { supabase } from "../Config/supabase";
import { v4 as uuidv4 } from "uuid";
import { UserLocationContext } from "../Context/UserLocationContext";

function FeedPage({ onAddToWantToGo }) {
  const [restaurants, setRestaurants] = useState(
    JSON.parse(localStorage.getItem("restaurants")) || []
  );
  const [selectedCategory, setSelectedCategory] = useState(
    localStorage.getItem("selectedCategory") || null
  );
  const [selectedCategoryName, setSelectedCategoryName] = useState(
    localStorage.getItem("selectedCategoryName") || ""
  );
  const [wantToGoList, setWantToGoList] = useState([]);
  const [locationAccessDenied, setLocationAccessDenied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const [searchQuery, setSearchQuery] = useState(
    localStorage.getItem("searchQuery") || ""
  );
  const [showCategories, setShowCategories] = useState(
    localStorage.getItem("showCategories") || "main"
  );
  const [filterBy, setFilterBy] = useState(
    localStorage.getItem("filterBy") || "country"
  );
  const [previousCategory, setPreviousCategory] = useState(
    localStorage.getItem("previousCategory") || "main"
  );
  const [loading, setLoading] = useState(false);
  const cacheRef = useRef({
    categoryData: new Map(),
    wantToGoList: new Set(),
    previousResults: JSON.parse(localStorage.getItem("previousResults")) || [],
  });

  const userLocation = useContext(UserLocationContext);

  const countryCategories = [
    { name: "Chinese", alias: "chinese", imageUrl: chinese },
    { name: "Japanese", alias: "japanese", imageUrl: japanese },
    { name: "Korean", alias: "korean", imageUrl: korean },
    { name: "Thai", alias: "thai", imageUrl: thai },
    { name: "Indian", alias: "indpak", imageUrl: indian },
    { name: "Mexican", alias: "mexican", imageUrl: mexicanImage },
    { name: "African", alias: "african", imageUrl: african },
    { name: "Caribbean", alias: "caribbean", imageUrl: caribbean },
    { name: "Mideast", alias: "mideastern", imageUrl: mideast },
    { name: "Italian", alias: "italian", imageUrl: italian },
  ];

  const typeCategories = [
    { name: "Coffee", alias: "coffee", imageUrl: coffeeImage },
    { name: "Ice Cream", alias: "icecream", imageUrl: iceCream },
    { name: "Brunch", alias: "breakfast_brunch", imageUrl: brunch },
    { name: "Bakeries", alias: "bakeries", imageUrl: bakeries },
    { name: "Pizza", alias: "pizza", imageUrl: pizza },
    { name: "Burger", alias: "burger", imageUrl: burger },
    { name: "Vegan", alias: "vegan", imageUrl: veganImage },
    { name: "Seafood", alias: "seafood", imageUrl: seafood },
    { name: "Fast Food", alias: "hotdogs", imageUrl: fast },
    {
      name: "Chicken",
      alias: ["chickenshop", "chicken_wings"],
      imageUrl: chicken,
    },
  ];

  const parkCategories = [
    { name: "Parks", alias: "parks", imageUrl: park },
    { name: "Trails", alias: "hiking", imageUrl: hike },
  ];

  useEffect(() => {
    const fetchWantToGoList = async () => {
      const user = auth.currentUser;
      if (user) {
        console.log("Authenticated user:", user);
        try {
          const { data, error } = await supabase
            .from("wantToGo")
            .select("restaurant_id")
            .eq("uid", user.uid);
          if (error) {
            console.error("Error fetching wantToGoList from Supabase:", error);
          } else {
            const wantToGoRestaurants = data.map((item) => item.restaurant_id);
            setWantToGoList(wantToGoRestaurants);
            cacheRef.current.wantToGoList = new Set(wantToGoRestaurants);
            console.log(
              "Fetched wantToGoList from Supabase:",
              wantToGoRestaurants
            );
          }
        } catch (error) {
          console.error("Error fetching wantToGoList from Supabase:", error);
        }
      } else {
        console.error("No authenticated user found");
      }
    };

    fetchWantToGoList();
  }, []);

  const performSearch = useCallback(
    _.debounce(async (query, latitude, longitude, location) => {
      try {
        const params = location
          ? { searchQuery: query, location }
          : { searchQuery: query, latitude, longitude };

        const response = await axios.get(
          `https://yelp-server-chi.vercel.app/api/restaurants`,
          {
            params,
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_YELP_API_KEY}`,
            },
          }
        );
        setRestaurants(response.data.businesses);
        localStorage.setItem(
          "restaurants",
          JSON.stringify(response.data.businesses)
        );
        localStorage.setItem("searchQuery", query);
        cacheRef.current.previousResults = response.data.businesses;
        localStorage.setItem(
          "previousResults",
          JSON.stringify(response.data.businesses)
        );
        console.log("Fetched search results:", response.data.businesses);
      } catch (error) {
        console.error(
          "Error on search submit:",
          error.response || error.message
        );
      }
    }, 500),
    []
  );

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query.trim()) {
      setShowCategories(false);
      setSelectedCategory(null);
    } else {
      setRestaurants(cacheRef.current.previousResults);
      setShowCategories(previousCategory);
    }
    if (zipCode) {
      performSearch(query, null, null, zipCode);
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          performSearch(
            query,
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.error("Error obtaining location:", error);
          setLocationAccessDenied(true);
          performSearch(query);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      performSearch(query);
    }
  };

  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value);
  };

  const handleZipCodeSearch = () => {
    if (zipCode.trim()) {
      fetchCategoryData(selectedCategory, zipCode);
      setAnchorEl(null);
    }
  };

  const handleAddRestaurantToWantToGo = async (restaurant) => {
    const user = auth.currentUser;
    if (user) {
      try {
        console.log("Adding to Want To Go for user:", user.uid);

        if (cacheRef.current.wantToGoList.has(restaurant.id)) {
          console.log("Restaurant already in Want To Go list.");
          return;
        }

        const { data, error } = await supabase.from("wantToGo").insert([
          {
            id: uuidv4(),
            uid: user.uid,
            restaurant_id: restaurant.id,
            name: restaurant.name,
            url: restaurant.url,
            location: `${restaurant.location.address1}, ${restaurant.location.city}, ${restaurant.location.state} ${restaurant.location.zip_code}`,
            rating: restaurant.rating,
            review_count: restaurant.review_count,
            image_url: restaurant.image_url,
            phone: restaurant.display_phone,
            latitude: restaurant.coordinates.latitude,
            longitude: restaurant.coordinates.longitude,
            price: restaurant.price,
            categories: restaurant.categories
              .map((cat) => cat.title)
              .join(", "),
            createdAt: new Date().toISOString(),
          },
        ]);
        if (error) throw error;
        console.log("Inserted to Supabase:", data);
        setWantToGoList((prevList) => [...prevList, restaurant.id]);
        cacheRef.current.wantToGoList.add(restaurant.id);

        // Notify WanderList about the new addition
        onAddToWantToGo(restaurant);
      } catch (error) {
        console.error("Error inserting to Supabase:", error);
      }
    } else {
      console.error("No authenticated user found");
    }
  };

  const fetchCategoryData = async (categoryAlias, location) => {
    if (!categoryAlias) {
      return;
    }
    setLoading(true); // Set loading state to true
    setRestaurants([]); // Clear the restaurants list
    if (cacheRef.current.categoryData.has(categoryAlias)) {
      setRestaurants(cacheRef.current.categoryData.get(categoryAlias));
      setLoading(false); // Set loading state to false
      return;
    }
    try {
      const params = location
        ? { location, category: categoryAlias, limit: 20, radius: 4000 }
        : {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
            category: categoryAlias,
            limit: 20,
            radius: 4000,
          };
      const response = await axios.get(
        `https://yelp-server-chi.vercel.app/api/restaurants`,
        {
          params,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_YELP_API_KEY}`,
          },
        }
      );
      const sortedRestaurants = response.data.businesses.sort(
        (a, b) => b.review_count - a.review_count
      );
      cacheRef.current.categoryData.set(categoryAlias, sortedRestaurants);
      setRestaurants(sortedRestaurants);
      localStorage.setItem("restaurants", JSON.stringify(sortedRestaurants));
      console.log("Fetched category data:", sortedRestaurants);
    } catch (error) {
      console.error(
        "Error fetching restaurants:",
        error.response || error.message
      );
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  const handleCategoryClick = (categoryAlias) => {
    const categoryName = [
      ...countryCategories,
      ...typeCategories,
      ...parkCategories,
    ].find((cat) => cat.alias === categoryAlias)?.name;
    setSelectedCategory(categoryAlias);
    localStorage.setItem("selectedCategory", categoryAlias);
    setSelectedCategoryName(categoryName);
    localStorage.setItem("selectedCategoryName", categoryName);
    setSearchQuery("");
    setPreviousCategory(showCategories);
    setShowCategories(false);
    console.log("Category selected:", categoryAlias);
    fetchCategoryData(categoryAlias);
  };

  const handleBackClick = () => {
    setSelectedCategory(null);
    localStorage.removeItem("selectedCategory");
    setSearchQuery("");
    setShowCategories(previousCategory);
    setPreviousCategory("main");
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const handleMainCardClick = (categoryAlias) => {
    setShowCategories(categoryAlias);
    setPreviousCategory("main");
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterByCountry = () => {
    setFilterBy("country");
    setShowCategories("cuisine");
  };

  const handleFilterByType = () => {
    setFilterBy("type");
    setShowCategories("cuisine");
  };

  const handleCurrentLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetchCategoryData(selectedCategory, {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          handleMenuClose();
        },
        (error) => {
          console.error("Error obtaining location:", error);
          setLocationAccessDenied(true);
          handleMenuClose();
        }
      );
    }
  };

  useEffect(() => {
    localStorage.setItem("showCategories", showCategories);
    localStorage.setItem("filterBy", filterBy);
    localStorage.setItem("previousCategory", previousCategory);
  }, [showCategories, filterBy, previousCategory]);

  return (
    <div className="category-container">
      <div className="search-bar-container">
        {showCategories !== "main" && (
          <IconButton
            onClick={handleBackClick}
            aria-label="Back"
            style={{ color: "#f0e68c" }}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <TextField
          className="search-bars"
          variant="outlined"
          placeholder="Search any place..."
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ color: "#f0e68c" }}>
                <SearchIcon />
              </InputAdornment>
            ),
            style: { color: "white" },
          }}
        />
        <div className="hamburger-menu">
          <IconButton
            aria-label="menu"
            style={{ color: "#f0e68c" }}
            onClick={handleMenuClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            getContentAnchorEl={null}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "black",
              },
            }}
            style={{ marginTop: "20px" }}>
            <MenuItem>
              <TextField
                label="Zip Code"
                variant="outlined"
                value={zipCode}
                onChange={handleZipCodeChange}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#f0e68c",
                    },
                }}
              />
            </MenuItem>
            <MenuItem
              onClick={handleZipCodeSearch}
              style={{ opacity: 0.8, cursor: "pointer", color: "white" }}>
              Search by Zip Code
            </MenuItem>
            <MenuItem
              onClick={handleCurrentLocationClick}
              style={{ opacity: 0.8, cursor: "pointer", color: "white" }}>
              Use Current Location
            </MenuItem>
          </Menu>
        </div>
      </div>

      {locationAccessDenied && (
        <p className="location-access-warning">
          Please allow location access for search and categories feature.
        </p>
      )}

      {!searchQuery && !selectedCategory && (
        <>
          {showCategories === "main" && (
            <>
              <h2 className="selected-category">Top Nearby</h2>
              <div
                className="main-category-card"
                onClick={() => handleMainCardClick("cuisine")}>
                <Typography
                  variant="h4"
                  component="h2"
                  className="main-category-card-content">
                  Cuisines
                </Typography>
                <div
                  className="category-preview"
                  style={{ backgroundImage: `url(${previewImage})` }}></div>
              </div>
              <div
                className="main-category-card"
                onClick={() => handleMainCardClick("activities")}>
                <Typography
                  variant="h4"
                  component="h2"
                  className="main-category-card-content">
                  Outdoor Activities
                </Typography>
                <div
                  className="category-preview"
                  style={{ backgroundImage: `url(${parkTrails})` }}></div>
              </div>
            </>
          )}

          {showCategories === "cuisine" && (
            <>
              <div className="feed-filter-buttons-container">
                <Button
                  variant="contained"
                  onClick={handleFilterByCountry}
                  className={`feed-filter-button ${
                    filterBy === "country" ? "active-filter-button" : ""
                  }`}>
                  Cuisine Origins
                </Button>
                <Button
                  variant="contained"
                  onClick={handleFilterByType}
                  className={`feed-filter-button ${
                    filterBy === "type" ? "active-filter-button" : ""
                  }`}>
                  Cuisine Types
                </Button>
              </div>
              <div className="categories-container">
                {filterBy === "country" &&
                  countryCategories.map((category, index) => (
                    <Card
                      key={index}
                      className="category-card"
                      onClick={() => handleCategoryClick(category.alias)}
                      style={{ backgroundImage: `url(${category.imageUrl})` }}>
                      <CardContent className="category-card-content">
                        <Typography gutterBottom variant="h5" component="div">
                          {category.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}

                {filterBy === "type" &&
                  typeCategories.map((category, index) => (
                    <Card
                      key={index}
                      className="category-card"
                      onClick={() => handleCategoryClick(category.alias)}
                      style={{ backgroundImage: `url(${category.imageUrl})` }}>
                      <CardContent className="category-card-content">
                        <Typography gutterBottom variant="h5" component="div">
                          {category.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
              </div>
            </>
          )}

          {showCategories === "activities" && (
            <div className="categories-container">
              {parkCategories.map((category, index) => (
                <Card
                  key={index}
                  className="category-card"
                  onClick={() => handleCategoryClick(category.alias)}
                  style={{ backgroundImage: `url(${category.imageUrl})` }}>
                  <CardContent className="category-card-content">
                    <Typography gutterBottom variant="h5" component="div">
                      {category.name}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </>
      )}

      {searchQuery && restaurants.length > 0 && (
        <>
          <h2 className="selected-category">Search Results</h2>
          <div className="feed-container">
            {restaurants.map((restaurant) => (
              <RestaurantCard
                key={restaurant.id}
                restaurant={restaurant}
                showAddButton={!wantToGoList.includes(restaurant.id)}
                onAddToWantToGo={handleAddRestaurantToWantToGo}
                userLocation={userLocation}
              />
            ))}
          </div>
        </>
      )}

      {selectedCategory && (
        <>
          <h2 className="selected-category">
            {capitalize(selectedCategoryName)}
          </h2>
          {locationAccessDenied && (
            <p className="location-access-warning">
              Please allow location access to view the feed page.
            </p>
          )}
          <div className="feed-container">
            {loading ? (
              <p>Loading...</p>
            ) : (
              restaurants.map((restaurant) => (
                <RestaurantCard
                  key={restaurant.id}
                  restaurant={restaurant}
                  showAddButton={!wantToGoList.includes(restaurant.id)}
                  onAddToWantToGo={handleAddRestaurantToWantToGo}
                  userLocation={userLocation}
                />
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default FeedPage;
