import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { auth } from "../Config/firebase";
import { supabase } from "../Config/supabase";
import RestaurantCard from "../FeedPage/RestaurantCard";
import { useCache } from "../Context/CacheContext";
import { useError } from "../Context/ErrorContext";
import { UserLocationContext } from "../Context/UserLocationContext";
import {
  Button,
  IconButton,
  Typography,
  Drawer,
  Checkbox,
  FormControlLabel,
  AppBar,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./WanderList.css";

const WanderList = () => {
  const { cache, updateCache } = useCache();
  const { error, clearError, setGeneralError } = useError();
  const [wantToGoList, setWantToGoList] = useState(cache.wantToGoList || []);
  const [preloadedImages, setPreloadedImages] = useState(
    cache.preloadedImages || {}
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [restaurantToDelete, setRestaurantToDelete] = useState(null);
  const [filter, setFilter] = useState("restaurant");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const userLocation = useContext(UserLocationContext);

  const categories = useMemo(
    () => [
      { name: "Vegan", alias: ["vegan"] },
      { name: "Chinese", alias: ["chinese"] },
      { name: "Japanese", alias: ["japanese", "sushi", "ramen"] },
      { name: "Korean", alias: ["korean", "korean food"] },
      { name: "Coffee", alias: ["coffee", "coffee & tea"] },
      {
        name: "Desserts",
        alias: ["desserts", "ice cream", "cake", "donuts", "shaved ice"],
      },
      { name: "Mexican", alias: ["mexican"] },
      { name: "African", alias: ["african"] },
      { name: "Pizza", alias: ["pizza"] },
      { name: "Burger", alias: ["burger"] },
      {
        name: "Brunch",
        alias: ["breakfast & brunch", "breakfast_brunch"],
      },
      { name: "Bakeries", alias: ["bakeries"] },
      { name: "Italian", alias: ["italian"] },
      { name: "Seafood", alias: ["seafood"] },
      { name: "Sushi", alias: ["sushi"] },
      { name: "Thai", alias: ["thai"] },
      { name: "Indian", alias: ["indpak"] },
      {
        name: "Mideastern",
        alias: ["mideastern", "middleeastern", "middle eastern"],
      },
      { name: "Fast Food", alias: ["fast food", "hotdogs"] },
      { name: "Lounges", alias: ["lounges"] },
      {
        name: "Chicken",
        alias: ["chickenshop", "chicken_wings", "chicken shop", "chicken_shop"],
      },
    ],
    []
  );

  const parkCategories = useMemo(
    () => [
      { name: "Parks", alias: ["parks"] },
      { name: "Trails", alias: ["hiking"] },
    ],
    []
  );

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(src);
      img.onerror = () => reject(src);
    });
  };

  const preloadImages = useCallback(
    (restaurants) => {
      const imagePromises = restaurants.map((restaurant) =>
        preloadImage(restaurant.image_url)
      );

      Promise.all(imagePromises)
        .then((images) => {
          const preloadedImages = {};
          restaurants.forEach((restaurant, index) => {
            preloadedImages[restaurant.restaurant_id] = images[index];
          });
          setPreloadedImages(preloadedImages);
          updateCache("preloadedImages", preloadedImages);
        })
        .catch((error) => {
          console.error("Error preloading images:", error);
        });
    },
    [updateCache]
  );

  const parseLocation = (location) => {
    if (!location) return {};
    const parts = location.split(", ");
    return {
      address1: parts[0] || "",
      city: parts[1] || "",
      state: parts[2]?.split(" ")[0] || "",
      zip_code: parts[2]?.split(" ")[1] || "",
      display_address: parts,
    };
  };

  const fetchWantToGoList = useCallback(
    async (userId) => {
      try {
        const { data, error } = await supabase
          .from("wantToGo")
          .select("*")
          .eq("uid", userId)
          .order("createdAt", { ascending: false });

        if (error) throw error;

        const fetchedRestaurants = data.map((doc) => ({
          ...doc,
          createdAt: new Date(doc.createdAt),
          categories:
            typeof doc.categories === "string"
              ? doc.categories.split(",")
              : doc.categories,
          coordinates: {
            latitude: doc.latitude,
            longitude: doc.longitude,
          },
          location: parseLocation(doc.location),
        }));

        preloadImages(fetchedRestaurants);
        setWantToGoList(fetchedRestaurants);
        updateCache("wantToGoList", fetchedRestaurants);
        clearError();
      } catch (error) {
        setGeneralError(
          "Sorry, feature is currently under maintenance. Please come back later. 😢"
        );
        console.error("Error fetching Wanderlist:", error);
      }
    },
    [preloadImages, clearError, setGeneralError, updateCache]
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchWantToGoList(user.uid);
      } else {
        setWantToGoList([]);
        updateCache("wantToGoList", []);
      }
    });

    return () => unsubscribe();
  }, [fetchWantToGoList, updateCache]);

  const handleDeleteFromWantToGo = async (restaurantId) => {
    try {
      const { error } = await supabase
        .from("wantToGo")
        .delete()
        .eq("restaurant_id", restaurantId);

      if (error) throw error;

      const updatedList = wantToGoList.filter(
        (restaurant) => restaurant.restaurant_id !== restaurantId
      );
      setWantToGoList(updatedList);
      updateCache("wantToGoList", updatedList);
    } catch (error) {
      console.error("Error removing from Want To Go:", error);
    }
  };

  const openDeleteDialog = (restaurantId) => {
    setRestaurantToDelete(restaurantId);
    setOpenDialog(true);
  };

  const handleDeleteConfirmation = async () => {
    if (restaurantToDelete) {
      await handleDeleteFromWantToGo(restaurantToDelete);
      setRestaurantToDelete(null);
      setOpenDialog(false);
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSubcategoryChange = (alias) => {
    const currentIndex = selectedSubcategories.indexOf(alias);
    const newChecked = [...selectedSubcategories];

    if (currentIndex === -1) {
      newChecked.push(alias);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedSubcategories(newChecked);
  };

  const filteredList = useMemo(() => {
    return wantToGoList.filter((item) => {
      const itemCategories = item.categories.map((cat) =>
        typeof cat === "string" ? cat.toLowerCase().trim() : ""
      );

      if (filter === "restaurant") {
        return (
          itemCategories.some((category) =>
            categories.some((cat) =>
              cat.alias.some((alias) => alias.includes(category))
            )
          ) &&
          (selectedSubcategories.length === 0 ||
            itemCategories.some((category) =>
              selectedSubcategories.some((subcategory) =>
                category.includes(subcategory)
              )
            ))
        );
      } else if (filter === "activity") {
        return (
          itemCategories.some((category) =>
            parkCategories.some((cat) => cat.alias.includes(category))
          ) &&
          (selectedSubcategories.length === 0 ||
            itemCategories.some((category) =>
              selectedSubcategories.includes(category)
            ))
        );
      }
      return false;
    });
  }, [filter, wantToGoList, selectedSubcategories, categories, parkCategories]);

  return (
    <div className="ListContainer">
      <AppBar position="static">
        <Toolbar className="navbar">
          <h2
            style={{
              color: "#f0e68c",
              flexGrow: 1,
              textAlign: "center",
              fontWeight: "500",
            }}>
            Wanderlist
          </h2>
          {(filter === "restaurant" || filter === "activity") && (
            <IconButton
              edge="end"
              style={{ color: "#f0e68c" }}
              aria-label="menu"
              onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          style: {
            top: "80px",
            height: "calc(100vh - 160px)",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            overflowY: "auto",
          },
        }}>
        <div className="drawer-container">
          <h3 className="drawer-title">Filter</h3>
          <div className="drawer-grid">
            {(filter === "restaurant" ? categories : parkCategories).map(
              (category) => (
                <FormControlLabel
                  key={category.alias[0]}
                  control={
                    <Checkbox
                      checked={
                        selectedSubcategories.indexOf(category.alias[0]) !== -1
                      }
                      onChange={() =>
                        handleSubcategoryChange(category.alias[0])
                      }
                      name={category.alias[0]}
                      style={{ color: "#f0e68c" }}
                    />
                  }
                  label={category.name}
                  style={{ color: "#f0e68c", display: "block" }}
                  className="drawer-checkbox"
                />
              )
            )}
          </div>
        </div>
      </Drawer>

      <div className="filter-buttons-container">
        <Button
          variant={filter === "restaurant" ? "contained" : "outlined"}
          onClick={() => setFilter("restaurant")}
          className="filter-button">
          Restaurant
        </Button>
        <Button
          variant={filter === "activity" ? "contained" : "outlined"}
          onClick={() => setFilter("activity")}
          className="filter-button">
          Activities
        </Button>
      </div>

      {error ? (
        <div className="empty-list-message">
          <Typography variant="h6" className="empty-list-message">
            {error}
          </Typography>
        </div>
      ) : filteredList.length === 0 ? (
        <div className="empty-list-message">
          <p>No places added to your Wanderlist yet.</p>
          <p>
            Navigate to the <a href="/feed">Feed</a> and use the
            <AddCircleIcon className="add-circle-icon" /> button to add places
            to your Wanderlist.
          </p>
        </div>
      ) : (
        <div className="feed-container">
          {filteredList.map((restaurant) => (
            <div
              key={restaurant.restaurant_id}
              className="restaurant-card-wrapper">
              <RestaurantCard
                restaurant={restaurant}
                preloadedImage={preloadedImages[restaurant.restaurant_id]}
                showAddButton={false}
                onDeleteFromWantToGo={() =>
                  openDeleteDialog(restaurant.restaurant_id)
                }
                userLocation={userLocation}
              />
            </div>
          ))}
        </div>
      )}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Delete Restaurant"}</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this restaurant from your Want To Go
          list?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirmation} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WanderList;
