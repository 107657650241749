import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey);

export const setSupabaseSession = async (token) => {
  console.log("Setting Supabase session with token:", token);
  const { data, error } = await supabase.auth.setSession({
    access_token: token,
    refresh_token: token,
  });
  if (error) {
    console.error("Error setting Supabase session:", error);
    throw error;
  }
  console.log("Supabase session data:", data);
  return data;
};
