import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  LinearProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import "./MobileExperienceCard.css"; // Import CSS for mobile styles
import foodPhoto from "../Assets/Images/default.png";
import parkPhoto from "../Assets/Images/park.png";
import { auth } from "../Config/firebase";
import { supabase } from "../Config/supabase";
import DeleteIcon from "@mui/icons-material/Delete";
import { Rating } from "@mui/material";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconEmpty": {
    color: "#fff",
  },
});

const MobileExperienceCard = ({
  experience,
  isExpanded,
  onClick,
  onSaveNote,
  onDeleteIconClick,
  preloadedImage,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableNote, setEditableNote] = useState(experience.notes);

  const fallbackImage =
    experience.category === "park/trail" ? parkPhoto : foodPhoto;

  const handleEditClick = (event) => {
    event.stopPropagation();
    setIsEditMode(true);
  };

  const handleDiscard = () => {
    setEditableNote(experience.notes);
    setIsEditMode(false);
  };

  const handleSaveNote = async () => {
    if (!auth.currentUser) {
      console.error("User not authenticated");
      return;
    }

    if (editableNote.trim() === "") {
      console.error("Note is empty");
      return;
    }

    try {
      const { error } = await supabase
        .from("experiences")
        .update({ notes: editableNote })
        .eq("id", experience.id);
      if (error) throw error;
      console.log("Note updated successfully with Supabase");

      onSaveNote({
        ...experience,
        notes: editableNote,
      });

      setIsEditMode(false);
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

  const handleCardAction = (event) => {
    if (isEditMode) {
      event.stopPropagation();
      return;
    }
    if (isExpanded && isFlipped) {
      onClick();
      setIsFlipped(false);
    } else if (isExpanded) {
      setIsFlipped(true);
    } else {
      onClick();
    }
  };

  let uploadDate = "Unknown Date";
  if (experience.createdAt && experience.createdAt.toDate) {
    uploadDate = experience.createdAt.toDate().toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  } else if (experience.createdAt instanceof Date) {
    uploadDate = experience.createdAt.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  useEffect(() => {
    if (preloadedImage) {
      // If a preloaded image is available, no further action needed
    }
  }, [preloadedImage]);

  return (
    <div
      className={`mobile-experience-card-container ${
        isExpanded ? "expanded" : ""
      }`}
      onClick={handleCardAction}>
      {!isExpanded && (
        <Card className="compact-view">
          <div
            className="card-background"
            style={{
              backgroundImage: `url(${
                preloadedImage || experience.imageUrl || fallbackImage
              })`,
            }}></div>
          <Box className="card-content" style={{ justifyContent: "flex-end" }}>
            <Typography gutterBottom variant="h7" component="div">
              {experience.name}
            </Typography>
            <Typography variant="body2" className="card-text">
              Location: {experience.location}
            </Typography>
            <DeleteIcon
              onClick={(event) => {
                event.stopPropagation();
                onDeleteIconClick();
              }}
              className="delete-icon"
            />
          </Box>
        </Card>
      )}

      {isExpanded && (
        <>
          {!isFlipped ? (
            <div className="card-details">
              <Card raised className="expanded-card">
                <div
                  className="card-background expanded-background"
                  style={{
                    backgroundImage: `url(${
                      preloadedImage || experience.imageUrl || fallbackImage
                    })`,
                  }}></div>
                <Box className="card-content">
                  <Typography gutterBottom variant="h6" component="div">
                    {experience.name}
                  </Typography>
                  <StyledRating
                    name="read-only"
                    value={Number(experience.rating)}
                    readOnly
                  />
                  <Typography variant="body2" className="card-text">
                    {experience.category === "restaurant" && (
                      <>
                        Food: {experience.foodOrdered} <br />
                        Spend: {experience.spend}$ <br />
                      </>
                    )}
                    {experience.category === "park/trail" && (
                      <>
                        <div className="crowd-level-container">
                          Crowd Level:
                          <LinearProgress
                            variant="determinate"
                            value={(experience.crowdLevel / 5) * 100}
                            sx={{
                              width: "50%",
                              height: 8,
                              borderRadius: 5,
                              bgcolor: "transparent",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#e6c629",
                              },
                              marginLeft: 1,
                            }}
                          />
                        </div>
                        Walking Trails: {experience.walkingTrails} <br />
                      </>
                    )}
                    Location: {experience.location} <br />
                    Date: {uploadDate}
                  </Typography>

                  <div className="card-actions">
                    <DeleteIcon
                      onClick={(event) => {
                        event.stopPropagation();
                        onDeleteIconClick();
                      }}
                      className="delete-icon"
                    />
                  </div>
                </Box>
              </Card>
            </div>
          ) : (
            <div className="card-back-mobile">
              <Card raised style={{ backgroundColor: "black" }}>
                <CardContent>
                  {isEditMode ? (
                    <>
                      <TextField
                        label="Edit Note"
                        multiline
                        fullWidth
                        value={editableNote}
                        onChange={(e) => setEditableNote(e.target.value)}
                        variant="outlined"
                        autoFocus
                        sx={{
                          "& label": {
                            color: "#e6c629 !important",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#e6c629  !important",
                            },
                            "& fieldset": {
                              borderColor: "#e6c629  !important",
                            },
                            "&:hover fieldset": {
                              borderColor: "#e6c629  !important",
                            },
                          },
                          "& .MuiInputBase-input": {
                            color: "white !important",
                          },
                        }}
                      />

                      <Button
                        onClick={handleSaveNote}
                        color="primary"
                        sx={{ color: "#e6c629" }}>
                        Save
                      </Button>
                      <Button
                        onClick={handleDiscard}
                        color="primary"
                        sx={{ color: "grey" }}>
                        Discard
                      </Button>
                    </>
                  ) : (
                    <>
                      <Typography variant="body2" color="white">
                        <p className="impressions"> Impressions </p>
                        <br />
                        {experience.notes}
                      </Typography>

                      <div className="edit-button-container">
                        <Button
                          onClick={handleEditClick}
                          color="primary"
                          style={{
                            alignSelf: "flex-end",
                            marginTop: "auto",
                            color: "#e6c629",
                          }}>
                          Edit
                        </Button>
                      </div>
                    </>
                  )}
                </CardContent>
              </Card>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MobileExperienceCard;
