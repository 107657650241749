import React, { createContext, useContext, useState } from "react";

const CacheContext = createContext();

export const CacheProvider = ({ children }) => {
  const [cache, setCache] = useState({
    experiences: [],
    wantToGoList: [],
    preloadedImages: {},
  });

  const updateCache = (key, value) => {
    setCache((prevCache) => ({
      ...prevCache,
      [key]: value,
    }));
  };

  return (
    <CacheContext.Provider value={{ cache, updateCache }}>
      {children}
    </CacheContext.Provider>
  );
};

export const useCache = () => {
  return useContext(CacheContext);
};
