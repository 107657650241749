import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";

const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const firebaseDatabaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const firebaseMessagingSenderId =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID;
const firebaseMeasurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  databaseURL: firebaseDatabaseURL,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessagingSenderId,
  appId: firebaseAppId,
  measurementId: firebaseMeasurementId,
};

const app = firebase.initializeApp(firebaseConfig);
const firestore = app.firestore();
const storage = app.storage();

export const auth = firebase.auth();
export const database = firebase.database();
export { firestore, storage, firebase };

export const createUserProfileDocument = async (user, additionalData) => {
  if (!user) return; // Use 'user' instead of 'auth'

  // For Firebase's Realtime Database, we use 'ref' instead of 'doc'
  const userRef = database.ref(`users/${user.uid}`);

  // For Firebase's Realtime Database, 'once' is used to fetch a value
  const snapshot = await userRef.once("value");

  if (!snapshot.exists()) {
    const { displayName, email } = user; // Use 'user' instead of 'auth'
    const createdAt = new Date();

    try {
      // 'set' is also used in Firebase's Realtime Database to set a value
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      console.error("Error creating user:", error);
    }
  }

  return userRef;
};

export default firebase;
