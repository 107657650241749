import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import "../Navbar/Navbar.css";
import SignUpModal from "../SignUpModal/SignUpModal";
import SignInModal from "../SignInModal/SignInModal";
import { auth } from "../Config/firebase";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "../TermsConditions/TermsConditions";
import useOnClickOutside from "../Hooks/useOnClickOutside";
import logo from "../Assets/Images/gold_logo.png";

function Navbar() {
  const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);
  const [termsConditionsVisible, setTermsConditionsVisible] = useState(false);
  const openPrivacyPolicy = () => setPrivacyPolicyVisible(true);
  const closePrivacyPolicy = () => setPrivacyPolicyVisible(false);
  const openTermsConditions = () => setTermsConditionsVisible(true);
  const closeTermsConditions = () => setTermsConditionsVisible(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [signUpModalVisible, setSignUpModalVisible] = useState(false);
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setDropdownVisible(false));

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeSignUpModal = () => {
    setSignUpModalVisible(false);
  };

  const closeSignInModal = () => {
    setSignInModalVisible(false);
  };

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        setDropdownVisible(false);
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const handleUserSignUp = (updatedUser) => {
    setCurrentUser(updatedUser);
    setSignUpModalVisible(false);
    window.location.reload();
  };

  const handleUserSignIn = (userData) => {
    setCurrentUser(userData);
    setSignInModalVisible(false);
    window.location.reload();
  };

  const renderInitials = () => {
    if (currentUser) {
      return currentUser.displayName
        ? currentUser.displayName.charAt(0).toUpperCase()
        : currentUser.email.charAt(0).toUpperCase();
    }
  };

  return (
    currentUser && (
      <nav className="navbar">
        <div className="navbar-section left">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <div className="navbar-section right">
          {currentUser && (
            <div className="profile-icon" onClick={toggleDropdown}>
              <>
                <FaUserCircle
                  size={30}
                  className={`desktop-icon${isMobile ? " hidden" : ""}`}
                />
                <span
                  className={`mobile-initials${!isMobile ? " hidden" : ""}`}>
                  {renderInitials()}
                </span>
              </>

              <span
                className={`user-name${isMobile ? " mobile-initials" : ""}`}>
                {currentUser.displayName
                  ? currentUser.displayName.charAt(0).toUpperCase() +
                    currentUser.displayName.slice(1)
                  : currentUser.email.charAt(0).toUpperCase() +
                    currentUser.email.split("@")[0].slice(1)}
              </span>

              {dropdownVisible && (
                <ul className="dropdown-menu" ref={dropdownRef}>
                  <>
                    <li>
                      <Link to="#" onClick={handleSignOut}>
                        Logout
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={openPrivacyPolicy}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={openTermsConditions}>
                        Terms & Conditions
                      </Link>
                    </li>
                  </>
                </ul>
              )}
            </div>
          )}
          <SignUpModal
            isOpen={signUpModalVisible}
            onRequestClose={closeSignUpModal}
            onUserSignUp={(updatedUser) => handleUserSignUp(updatedUser)}
          />
          <SignInModal
            isOpen={signInModalVisible}
            onRequestClose={closeSignInModal}
            onUserSignIn={handleUserSignIn}
          />
          <PrivacyPolicy
            isOpen={privacyPolicyVisible}
            onRequestClose={closePrivacyPolicy}
          />
          <TermsConditions
            isOpen={termsConditionsVisible}
            onRequestClose={closeTermsConditions}
          />
        </div>
      </nav>
    )
  );
}

export default Navbar;
