import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import { TextField, Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FaCheck } from "react-icons/fa"; // Import the check icon
import "./Contact.css"; // Ensure to create this CSS file

const serviceId = process.env.REACT_APP_SERVICE_ID;
const templateId = process.env.REACT_APP_TEMPLATE_ID;
const userId = process.env.REACT_APP_EMAILJS_USER_ID;

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
  },
  inputField: {
    margin: theme.spacing(1),
    width: "90%", // Or '100%' if you want it to be responsive
    "& label.Mui-focused": {
      color: "white", // Label color when the input is focused
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white", // Border color of the input field
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& input::placeholder": {
      color: "white !important", // Placeholder text color
      opacity: 1, // Ensure the placeholder text is fully opaque
    },
    "& .MuiInputLabel-root": {
      color: "white !important",
      opacity: 0.6, // Label color
    },
    "& input": {
      color: "white !important", // Text color when typing
    },
    "& textarea": {
      color: "white !important", // Text color for multiline input (textarea)
    },
    [theme.breakpoints.down("sm")]: {
      // Adjust 'sm' to your mobile breakpoint
      width: "92%", // 95% width on mobile devices
    },
  },
  submitButton: {
    margin: theme.spacing(2),
    width: "40%",
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "black",
      opacity: "0.3",
    },
  },
  submitButtonSuccess: {
    margin: theme.spacing(2),
    width: "40%",
    backgroundColor: "#aaccb8",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#aaccb8",
      opacity: "0.3",
    },
  },
  submitMessage: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: "rgba(0, 0, 0, 0.3)", // Black background with opacity
    color: "white",
    borderRadius: "4px",
    textAlign: "center",
  },
  successIcon: {
    fontSize: "24px",
    color: "white",
  },
}));

function Contact() {
  const classes = useStyles();
  const [submitMessage, setSubmitMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceId, templateId, e.target, userId).then(
      (result) => {
        console.log(result.text);
        setSubmitMessage("Message sent successfully!");
        setIsSuccess(true);
        e.target.reset();
        setTimeout(() => {
          setSubmitMessage("");
          setIsSuccess(false);
        }, 1500);
      },
      (error) => {
        console.log(error.text);
        setSubmitMessage(
          "Failed to send the message, please try again or use email to reach us."
        );
        setIsSuccess(false);
        setTimeout(() => {
          setSubmitMessage("");
        }, 2000);
      }
    );
  };

  return (
    <div className="custom-contact" id="contact">
      <div className="custom-contact-container">
        <div className="custom-get-in-touch">
          <h2 className="custom-keep-in-touch">Let's Get In Touch</h2>
          <form className={classes.form} onSubmit={sendEmail} ref={form}>
            <div className="custom-inputRow">
              <TextField
                label="Name"
                name="from_name"
                required
                variant="outlined"
                className={classes.inputField}
              />
              <TextField
                label="Email"
                name="from_email"
                required
                variant="outlined"
                className={classes.inputField}
                type="email"
              />
            </div>
            <TextField
              label="Message"
              name="message"
              required
              variant="outlined"
              className={classes.inputField}
              multiline
              rows={4} // This ensures the text area is visible
            />
            {isSuccess ? (
              <Button className={classes.submitButtonSuccess}>
                <FaCheck className={classes.successIcon} />
              </Button>
            ) : (
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.submitButton}>
                Send
              </Button>
            )}
            {submitMessage && (
              <Paper className={classes.submitMessage}>
                <Typography variant="body1">{submitMessage}</Typography>
              </Paper>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
