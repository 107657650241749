import React, { createContext, useContext, useState } from "react";

const ErrorContext = createContext();

export const useError = () => {
  return useContext(ErrorContext);
};

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState("");

  const setQuotaError = (message) => {
    setError(message);
  };

  const setGeneralError = (message) => {
    setError(message);
  };

  const clearError = () => {
    setError("");
  };

  return (
    <ErrorContext.Provider
      value={{ error, setQuotaError, setGeneralError, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
};
