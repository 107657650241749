import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Navbar from "./Navbar/Navbar";
import Home from "./Home/Home";
import Footer from "./Footer/Footer";
import AddExperience from "./AddExperience/AddExperience";
import Profile from "./Profile/Profile";
import FeedPage from "./FeedPage/FeedPage";
import WanderList from "./WanderList/WanderList";
import { UserLocationProvider } from "./Context/UserLocationContext";
import Contact from "./Contact/Contact";
import DesktopLandingPage from "./ViewControl/DesktopLandingPage";
import { auth } from "./Config/firebase";
import { CacheProvider } from "./Context/CacheContext"; // Import CacheProvider
import { ErrorProvider } from "./Context/ErrorContext";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [wantToGoList, setWantToGoList] = useState([]);
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, []);

  const handleAddToWantToGo = (newRestaurant) => {
    setWantToGoList((prevList) => [...prevList, newRestaurant]);
  };

  return (
    <UserLocationProvider>
      <ErrorProvider>
        <CacheProvider>
          <div className="app-container">
            {isMobile ? (
              <Router>
                <Navbar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/add-experience" element={<AddExperience />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route
                    path="/feed"
                    element={<FeedPage onAddToWantToGo={handleAddToWantToGo} />}
                  />
                  <Route
                    path="/want-to-go"
                    element={<WanderList wantToGoList={wantToGoList} />}
                  />
                  <Route path="/contact" element={<Contact />} />
                </Routes>
                <Footer isAuthenticated={isAuthenticated} />
              </Router>
            ) : (
              <DesktopLandingPage />
            )}
          </div>
        </CacheProvider>
      </ErrorProvider>
    </UserLocationProvider>
  );
}

export default App;
