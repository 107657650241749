// Footer.js
import React from "react";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import PlaceIcon from "@mui/icons-material/Place";
import { IconButton, Typography, Box } from "@mui/material";
import "./Footer.css"; // Ensure the path to Footer.css is correct

function Footer({ isAuthenticated }) {
  // Only render the footer if the user is authenticated
  if (!isAuthenticated) {
    return null;
  }
  return (
    <Box className="footer" display="flex" justifyContent="space-around">
      <Box textAlign="center">
        <Link to="/" className="footer-button">
          <IconButton color="inherit">
            <HomeIcon />
          </IconButton>
          <Typography variant="caption" display="block" color="inherit">
            Diary
          </Typography>
        </Link>
      </Box>
      <Box textAlign="center">
        <Link to="/feed" className="footer-button">
          <IconButton color="inherit">
            <RssFeedIcon />
          </IconButton>
          <Typography variant="caption" display="block" color="inherit">
            Feed
          </Typography>
        </Link>
      </Box>
      <Box textAlign="center">
        <Link to="/want-to-go" className="footer-button">
          <IconButton color="inherit">
            <PlaceIcon />
          </IconButton>
          <Typography variant="caption" display="block" color="inherit">
            Wanderlist
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

export default Footer;
